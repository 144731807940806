import React from "react";
import moment from "moment";
import SeasonUiEvent from "./SeasonUiEvent";

const SeasonUI = ({ input }) => {
  // console.log(input);
  const { h2, year, events } = input;

  const _getDateMMYY = (d) => {
    // console.log(d)
    const date = moment(d);
    return date.format("MM.DD");
  };

  return (
    <section id="programmation" className="module season-ui mb-xxl py-md">
      <div className="px-_md md:px-md md:mb-xl mb-xl">
        <h2 className="flex justify-between text-xl container relative z-10">
          <span className="h2">{h2}</span>
          <span>{year}</span>
        </h2>
      </div>
      <div className="events">
        {events.map((item, i) => (
          <SeasonUiEvent key={i} input={item} />
        ))}
      </div>
    </section>
  );
};

export default SeasonUI;
