import moment from "moment";

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function _datesDatesDuAu(du, au) {
  const dateDu = moment(du);
  const dateAu = moment(au);
  const isSameDay = dateDu.format("DD") === dateAu.format("DD");
  const isSameMonth = dateDu.format("MM") === dateAu.format("MM");
  const isSameYear = dateDu.format("YY") === dateAu.format("YY");

  let _du = "";
  if (!isSameDay) _du += `${dateDu.format("DD")}`;
  if (!isSameMonth) _du += `.${dateDu.format("MM")}`;
  if (!isSameYear) _du += `.${dateDu.format("YY")}`;

  let _au = `${dateAu.format("DD")}.${dateAu.format("MM")}.${dateAu.format(
    "YY"
  )}`;

  const dateFormated = !isSameDay ? `${_du}-${_au}` : `${_au}`;

  return dateFormated;
}
