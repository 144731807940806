import React from "react";
import clsx from "clsx";
import SanityTexte from "../SanityTexte";

const TitleTexteUI = ({ input }) => {
  // console.log(input);
  const { title, titleL, texte, texteL } = input;
  return (
    <section className="title-texte-ui px-_md md:px-md mb-150">
      <div className="container">
        <div className={clsx("title-texte-item", titleL ? "has-title-l" : "")}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
              <h2 className={clsx(titleL ? "text-lg mb-_xl md:mb-0" : "mb-_lg")}>
                {title}
              </h2>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className={clsx("content", texteL ? "text-lg" : "")}>
                <SanityTexte input={texte} />
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleTexteUI;
