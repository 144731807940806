import React from "react";
import SanityImage from "../SanityImage";

const MosaicUI = ({ input }) => {
  // console.log(input);
  const { columnRight, imagesLeft, imagesRight } = input;
  return (
    <section className="module mosaic-ui px-_md md:px-md py-xl mb-xxl">
      <div className="container">
        <div className="row">
          <div className={`col-xs-12 col-xs `}>
            {imagesLeft.map((item, i) => (
              <div className="mosaic-item mb-md" key={i}>
                <SanityImage input={item.image} caption={item.caption} />
              </div>
            ))}
          </div>
          <div className={`col-xs-12 col-md-${columnRight}`}>
            {imagesRight.map((item, i) => (
              <div className="mosaic-item mb-md " key={i}>
                <SanityImage input={item.image} caption={item.caption} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MosaicUI;
