import React from "react";
import Artistes from "./Artistes";
import { _datesDatesDuAu } from "../../core/utils";

const ArchiveEventsUI = ({ input, events }) => {
  return (
    <section
      id="souvenirs"
      className="module archive-events-ui mb-xxl px-_md md:px-md"
    >
      <div className="container">
        <h2 className="text-xl mb-lg">{input.h2}</h2>
        <div className="events ">
          {input.seasons.map((season, i) => {
            const seasonEvents = events
              .filter(
                (event) =>
                  event.next && event.next._rawSeason._id === season._id
              )
              .map((event) => event.next);
            return (
              <div key={i} className="events-group mb-lg">
                <h3 className="py-xs">
                  {season.title} {season.year}
                </h3>
                <ul className=" list">
                  {seasonEvents.map((item, i) => (
                    <li
                      key={i}
                      className="tr flex flex-wrap md:flex-nowrap py-xs "
                    >
                      <div className="w-full md:w-5/12 order-3 md:order-1 title pr-md">
                        <div>{item.title}</div>
                        <Artistes
                          artistesPrefix={item.artistesPrefix}
                          artistes={item.artistes}
                        />
                      </div>
                      <div className="w-6/12 md:w-2/12 order-1 md:order-2 category">
                        {item.category}
                      </div>
                      <div className="w-6/12 md:w-5/12 order-2 md:order-3">
                        <div className="dates flex justify-end">
                          {_datesDatesDuAu(item.dates.du, item.dates.au)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ArchiveEventsUI;
