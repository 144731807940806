import React from "react";
// import Texte from "./Texte";
// import ProjectListe from "./ProjectListe";
// import TexteListe from "./TexteListe"
import HeroUI from "./HeroUI";
import SeasonUI from "./SeasonUI";
import ImagesUI from "./ImagesUI";
import MosaicUI from "./MosaicUI";
import ArchiveEventsUI from "./ArchiveEventsUI";
import TitleTexteUI from "./TitleTexteUI";
const Modules = ({ input, events }) => {
  // console.log(input)

  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      // return null;
      // console.log(module)
      switch (module._type) {
        case "heroUI":
          return <HeroUI key={i} input={module} />;
        case "seasonUI":
          return <SeasonUI key={i} input={module} />;
        case "mosaicUI":
          return <MosaicUI key={i} input={module} />;
        case "archiveEventsUI":
          return <ArchiveEventsUI key={i} input={module} events={events} />;
        case "titleTexteUI":
          return <TitleTexteUI key={i} input={module} />;
        default:
          return null;
      }
    });
    return _modules;
  };

  return <div className="modules">{_renderModules()}</div>;
};

export default Modules;
