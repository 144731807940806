import React from "react";

const Artistes = ({ artistesPrefix, artistes }) => {
  // const { artistesPrefix, artistes } = input;
  return (
    <div className="artistes">
      {artistes.length > 1 && (
        <p className="">
          <span>{artistesPrefix}</span>{" "}
          {artistes.map((artiste, i) => (
            <span key={i}>
              {artiste.url && (
                <a
                  href={artiste.url}
                  target="_blank"
                  rel="noopener, noreferrer"
                >
                  <span>{artiste.name}</span>
                </a>
              )}

              {!artiste.url && <span>{artiste.name}</span>}

              {i < artistes.length - 2 && <>, </>}
              {artistes.length > 1 && i === artistes.length - 2 && <> et </>}
            </span>
          ))}
        </p>
      )}

      {artistes.length === 1 && (
        <p>
          <span>{artistesPrefix}</span>{" "}
          <a href={artistes[0].url} target="_blank" rel="noopener, noreferrer">
            {artistes[0].name}
          </a>
        </p>
      )}
    </div>
  );
};

export default Artistes;
