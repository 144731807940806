import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SanityImage from "../SanityImage";
import clsx from "clsx";
import useDeviceDetect from "../../hooks/useDeviceDetect";

const maxBlur = 120; // 200

const HeroUI = ({ input }) => {
  // console.log(input);
  const [blur, setBlur] = useState(120);
  const [opacity, setOpacity] = useState(1);
  // const [image, setImage] = useState(input.image);
  const { isMobile } = useDeviceDetect();
  // const image = isMobile ? input.imageMobile : input.image;
  // console.log(image);
  useEffect(() => {
    _onScroll();
    window.addEventListener("scroll", _onScroll);

    return () => window.removeEventListener("scroll", _onScroll);
  }, []);

  // useEffect(() => {
  //   setImage(isMobile ? input.imageMobile : input.image);
  // }, [isMobile]);

  const _onScroll = () => {
    _handleBlur();
    _handleOpacity();
  };

  const _handleBlur = () => {
    const { innerHeight, pageYOffset } = window;
    // const multiplier = pageYOffset< innerHeight ? -1 : 1
    // const _scrollPercent = (pageYOffset * 100) / (innerHeight / 2);
    // const newBlur = 200 - _scrollPercent;
    // const _blur = newBlur > 0 ? newBlur : 0;
    const multiplier = 1.2; //120px de blur

    const diff = innerHeight / multiplier - pageYOffset;
    const perc = (diff / (innerHeight / multiplier)) * 100;
    const _blur = perc * multiplier > 0 ? perc * multiplier : 0;
    // console.log(_blur);

    setBlur(_blur);
  };

  const _handleOpacity = () => {
    const { innerHeight, pageYOffset } = window;
    if (pageYOffset > innerHeight) {
      const diff = pageYOffset - innerHeight;
      const perc = 1 - diff / innerHeight;
      // console.log(diff, perc);
      const _opacity = perc > 0 ? perc : 0;
      setOpacity(_opacity);
    }
  };

  const isPortait = input.image.asset.metadata.dimensions.aspectRatio < 1;
  // console.log(isPortait);
  return (
    <section className={clsx("module hero-ui", isPortait ? "is-portait" : "")}>
      <div
        className="image fixed w-full h-full z-0"
        style={{
          filter: `blur(${blur}px)`,
          opacity: opacity,
        }}
      >
        <div className="sm-only">
          <SanityImage input={input.imageMobile} cover={false} />
        </div>
        <div className="hidden-sm">
          <SanityImage input={input.image} cover={true} />
        </div>
      </div>
      <div className="text-xl p-_md md:p-md">
        <div className="row">
          <div className="col-lg-10 col-xs-12">{input.texte}</div>
        </div>
      </div>
    </section>
  );
};

export default HeroUI;
