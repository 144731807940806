import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Modules from "../components/modules";

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPageModulaire(slug: { current: { eq: $slug } }) {
      home
      title
      seo {
        metaTitle
        metaDescription
        posterImage {
          asset {
            url
          }
        }
      }
      _rawModules(resolveReferences: { maxDepth: 10 })
    }
    allSanityEvent(sort: { fields: dates___du, order: DESC }) {
      edges {
        next {
          _rawSeason(resolveReferences: { maxDepth: 10 })
          title
          artistesPrefix
          artistes {
            name
            url
          }
          category
          dates {
            au
            du
            time
          }
        }
      }
    }
  }
`;

const PageModulaire = ({ data }) => {
  // console.log(data.sanityPageModulaire)
  const { home, seo, _rawModules } = data.sanityPageModulaire;
  const { edges: events } = data.allSanityEvent;
  // useEffect(() => {}, [])

  return (
    <div className="page">
      {seo && (
        <Seo
          pageTitle={seo.metaTitle ? seo.metaTitle : "Page title missing"}
          pageDescription={
            seo.metaDescription
              ? seo.metaDescription
              : "Page description missing"
          }
          pageImage={seo.posterImage?.asset?.url}
          template={`${home ? "home" : ""} template-modulaire`}
          page={!home}
        />
      )}
      {_rawModules && <Modules input={_rawModules.modules} events={events} />}
    </div>
  );
};

export default PageModulaire;
