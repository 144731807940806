import React from "react";
// import moment from "moment";
import SanityTexte from "../SanityTexte";
import Artistes from "./Artistes";
import { _datesDatesDuAu } from "../../core/utils";

const SeasonUiEvent = ({ input }) => {
  const {
    bgColor,
    category,
    location,
    dates,
    artistes,
    artistesPrefix,
    title,
    texte,
  } = input;
  // console.log(input);
  const _getDateMMYY = (d) => {
    // console.log(d)
    const date = moment(d);
    return date.format("MM.DD");
  };
  const datesDuAu = _datesDatesDuAu(dates.du, dates.au);

  return (
    <article className="event px-_md md:px-md py-xl ">
      <div
        className="bg absolute w-full h-full z-0"
        style={{
          background: bgColor.value,
        }}
      ></div>
      <div className="container">
        <ul className="metas flex justify-between md:justify-start relative mb-_md md:mb-xs">
          {category && <li className="category mr-md">{category}</li>}
          {location && <li className="location hidden-sm">{location}</li>}
          {dates && dates.time && (
            <>
              <li className="pipe px-sm hidden-sm">|</li>
              <li className="time hidden-sm"> {dates.time}</li>
            </>
          )}
          <li className="sm-only">
            <div className="dates text-right flex">{datesDuAu}</div>
          </li>
        </ul>
        <div className="header text-xl mb-_md md:mb-xl">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <h3>{title}</h3>
            </div>
            <div className="col-md-4  col-xs-12">
              <div className="dates text-right hidden-sm">{datesDuAu}</div>
            </div>
          </div>
        </div>
        <div className="sm-only">
          <div className="mb-_md">
            <div className="location">{location}</div>
            {dates && dates.time && <div className="time"> {dates.time}</div>}
          </div>
        </div>

        <div className="content md:w-6/12">
          <div className="mb-md">
            <Artistes artistesPrefix={artistesPrefix} artistes={artistes} />
          </div>

          <SanityTexte input={texte} />
        </div>
      </div>
    </article>
  );
};

export default SeasonUiEvent;
